import React from "react";
import styled, { css } from "styled-components";

import { PlayerContext } from "./player";
import { Button } from "./button";

import PlayButtonIcon from "../assets/play-button.svg";
import { bannerTrack } from "../data";

const grid = css`
  display: grid;
  grid-gap: ${props => props.theme.gutter}px;
  grid-template-columns: repeat(12, 1fr);
  @media (max-width: ${props => props.theme.breakpoints.m}px) {
    display: block;
  }
`;

const Banner = styled.section`
  margin-top: ${props => props.theme.spacer * 28}px;
  ${grid}
  background: url("bluejay.png") no-repeat top right;
  padding-bottom: ${props => props.theme.spacer * 50}px;

  @media (max-width: ${props => props.theme.breakpoints.l}px) {
    background: none;
    margin-top: ${props => props.theme.spacer * 8}px;
    padding-bottom: ${props => props.theme.spacer * 8}px;
  }

  h1 {
    color: ${props => props.theme.colors.primary};
    line-height: 1.4;
    grid-column-start: 1;
    grid-column-end: 7;
    margin: 0;
  }

  p {
    font-size: ${props => props.theme.baseFontSize * 1.5}px;
    line-height: 1.5;
    font-weight: 600;
    grid-column-start: 1;
    grid-column-end: 6;
    margin: 0;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    grid-column-start: 1;
    grid-column-end: 4;

    @media (max-width: ${props => props.theme.breakpoints.l}px) {
      justify-content: space-evenly;
    }

    ${Button} {
      display: flex;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
`;

export default () => (
  <Banner>
    <h1>Royalty free music and audio files tailored to your needs.</h1>
    <p>We create stock and custom music for your video, game or podcast.</p>
    <div className="actions">
      <PlayerContext.Consumer>
        {player => (
          <Button onClick={() => player.play(bannerTrack)}>
            <PlayButtonIcon />
            {bannerTrack.title}
          </Button>
        )}
      </PlayerContext.Consumer>
      <a href={bannerTrack.buyUrl} rel="noopener noreferrer" target="_blank">
        Buy
      </a>
    </div>
  </Banner>
);
