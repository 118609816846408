export const tracks = [
  {
    title: "Game background",
    streamUrl: "/game-background.mp3",
    coverUrl: "/game-background@2x.png",
    buyUrl: "https://audiojungle.net/item/game-background/10684728",
    category: "Video Game"
  },
  {
    title: "Voyage",
    streamUrl: "/voyage.mp3",
    coverUrl: "/voyage@2x.png",
    buyUrl: "https://audiojungle.net/item/voyage/8801591",
    category: "Action / Adventure"
  },
  {
    title: "Jazz Logo",
    streamUrl: "/jazz-logo.mp3",
    coverUrl: "/jazz-logo@2x.png",
    buyUrl: "https://audiojungle.net/item/jazz-logo-2/10285248",
    category: "Logo / Ident"
  }
];

export const bannerTrack = {
  title: "A Little Waltz",
  streamUrl: "/a-little-waltz.mp3",
  coverUrl: "/a-little-waltz@2x.png",
  buyUrl: "https://audiojungle.net/item/a-little-waltz/7157125",
  category: "Waltz"
};
