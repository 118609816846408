import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { PlayerContext } from "../components/player";
import { Button, BlurButton } from "../components/button";
import { tracks } from "../data";
import Banner from "../components/banner";

import PlayButtonIcon from "../assets/play-button.svg";

const Panel = styled.li`
  box-shadow: 0 10px 20px 0 rgba(16, 60, 150, 0.1);
  border-radius: 8px;
  max-width: 350px;
  min-width: calc(215px + 32px);
  padding: 16px;
  flex: 1 1 0;
  width: 0;
  box-sizing: border-box;

  picture {
    display: block;
    text-align: center;
    img {
      border-radius: 8px;
    }
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 12px;
    line-height: 2;
    margin: 0;
  }
`;

const MusicPanel = styled.li`
  box-shadow: 0 10px 20px 0 rgba(16, 60, 150, 0.1);
  border-radius: 8px;
  max-width: 350px;
  box-sizing: border-box;

  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => `url(${props.coverImg})`};
    background-repeat: no-repeat;
    background-size: cover;
    width: 350px;
    height: 200px;
    border-radius: 8px;

    @media (max-width: 360px) {
      width: 100%;
    }

    ${Button} {
      font-weight: 600;
      padding: 10px 40px;
    }
  }
  .content {
    padding: 20px;
    h3 {
      margin: 0;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      margin: 10px 0;
    }
    ${Button} {
      font-weight: 600;
      padding: 10px 40px;
    }
    .actions {
      text-align: right;
    }
  }
`;

const MusicPanelWrapper = ({
  children,
  title,
  onPlayClick,
  buyUrl,
  ...props
}) => {
  return (
    <MusicPanel {...props}>
      <div className="cover">
        <BlurButton onClick={onPlayClick}>
          <PlayButtonIcon />
          Play
        </BlurButton>
      </div>
      <div className="content">
        <h3>{title}</h3>
        {children}
        <div className="actions">
          <Button href={buyUrl} rel="noopener noreferrer" target="_blank">
            Buy
          </Button>
        </div>
      </div>
    </MusicPanel>
  );
};

const PanelList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  ${Panel}, ${MusicPanel} {
    margin: 16px;
    /* &:last-child {
      margin-right: 0;
    } */

    @media (max-width: 360px) {
      width: 100%;
    }

    @media (max-width: ${props => props.theme.breakpoints.l}px) {
      /* margin: 8px; */
      /* margin-bottom: 16px; */
    }
  }
`;

const CallToActionSection = styled.section`
  margin-bottom: 90px;
  .actions {
    display: flex;
    justify-content: center;
  }
`;

export default () => (
  <>
    <Banner />
    <CallToActionSection>
      <h2>Custom Music</h2>
      <PanelList>
        <Panel>
          <picture>
            <img src="video-music.png" srcSet="video-music@2x.png 2x" />
          </picture>
          <h3>Video music</h3>
          <p>
            Boost your product with decent backdrop! Try ready-to-use
            commercial, corporate or cinematic music and level-up your footage.
          </p>
        </Panel>
        <Panel>
          <picture>
            <img src="game-music.png" srcSet="game-music@2x.png 2x" />
          </picture>
          <h3>Game music</h3>
          <p>
            Working on a soundtrack for your new video game? Take a look on
            action-adventure, funny or scarry tracks and complete the story with
            immersive music!
          </p>
        </Panel>
        <Panel>
          <picture>
            <img
              src="audio-production.png"
              srcSet="audio-production@2x.png 2x"
            />
          </picture>
          <h3>Audio Production</h3>
          <p>
            Keep full control over every aspect of your project and order
            customized music track tailored to your needs!
          </p>
        </Panel>
        <Panel>
          <picture>
            <img src="other.png" srcSet="other@2x.png 2x" />
          </picture>
          <h3>Other</h3>
          <p>
            Looking for particular SFX? Want to create unforgettable and offbeat
            project? Or just need to edit and adjust a track? Tell us about your
            expectations and let us do what we love to do - create music!
          </p>
        </Panel>
      </PanelList>
      <div className="actions">
        <Button
        primary
        href="/order-custom-music/"
        onClick={e => {
          navigate("/order-custom-music/");
          e.preventDefault();
        }}>Order Custom Music</Button>
      </div>
    </CallToActionSection>
    <PlayerContext.Consumer>
      {player => (
        <CallToActionSection>
          <h2>Popular Stock Music</h2>
          <PanelList>
            {tracks.map(track => (
              <MusicPanelWrapper
                key={track.streamUrl}
                coverImg={track.coverUrl}
                title={track.title}
                buyUrl={track.buyUrl}
                onPlayClick={() => player.play(track)}
              >
                <p>{track.category}</p>
              </MusicPanelWrapper>
            ))}
          </PanelList>
          <div className="actions">
            <Button
            primary
            href="/order-custom-music/"
            onClick={e => {
              navigate("/order-custom-music/");
              e.preventDefault();
            }}>
              Order Custom Music
            </Button>
          </div>
        </CallToActionSection>
      )}
    </PlayerContext.Consumer>
  </>
);
